<template>
  <el-dialog v-loading="loading" v-model="visible" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" width="1140px" class="add" top="40px">
    <el-form
      ref="form"
      :model="ruleForm"
      :rules="rules"
      class="form"
      label-width="140px"
    >
      <div class="top">
        <el-form-item label="产品" prop="productTypeId">
          <el-select
            v-model="ruleForm.productTypeId"
            filterable
            placeholder="请选择"
            @change="onChange"
          >
            <el-option
              v-for="(item, index) in productTypeList"
              :key="index"
              :label="item.productTypeName"
              :value="item._id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" label-width="0" prop="productSpecId">
          <el-select
            :disabled="!ruleForm.productTypeId"
            filterable
            v-model="ruleForm.productSpecId"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in productSpecList"
              :key="index"
              :label="item.productSpecsName"
              :value="item._id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="产品名称" prop="productName">
          <el-input
            placeholder="请输入"
            v-model="ruleForm.productName"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="产品结构">
          <el-select v-model="ruleForm.productStructure" filterable clearable placeholder="请选择">
            <el-option v-for="(item,index) in PRODUCT_MIX" :key="index" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="厚度">
          <el-input placeholder="请输入" v-model="ruleForm.thickness"></el-input>
        </el-form-item>
        <el-form-item label="成品规格">
          <el-input
            placeholder="请输入"
            v-model="ruleForm.productSpec"
          ></el-input>
        </el-form-item>
        <el-form-item label="数量">
          <el-input placeholder="请输入" v-model="ruleForm.quantity"></el-input>
        </el-form-item>
      </div>
      <el-form-item label="原材料信息" class="rawMaterial">
        <div class="raw_content">
          <div class="head">
            <span>工艺名称</span>
            <span>原料</span>
            <span>原料规格</span>
            <span>下单数量</span>
          </div>
          <div
            v-for="(item, index) in ruleForm.rawMaterial"
            :key="index"
            class="rawMaterial_item"
          >
            <!-- 工艺名称 -->
            <el-form-item
              :prop="`rawMaterial.${index}.name`"
              :rules="rules.name"
            >
              <el-select v-model="item.name" filterable placeholder="请选择">
                <el-option v-for="(item,index) in CRAFT" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <!-- 原料 -->
            <el-form-item
              :prop="`rawMaterial.${index}.require`"
              :rules="rules.require"
            >
              <el-select v-model="item.require" filterable placeholder="请选择" @change="val => handleTypeChange(val,item,index)">
                <el-option v-for="(item,index) in allType" :key="index" :label="item.type" :value="item._id"></el-option>
              </el-select>
            </el-form-item>
            <!-- 原料规格 -->
            <el-form-item
              :prop="`rawMaterial.${index}.specs`"
              :rules="rules.specs"
            >
              <!-- <el-input v-model="item.specs" placeholder="请输入"></el-input> -->
              <el-select v-model="item.specs" filterable placeholder="请选择">
                <el-option v-for="(item,index) in item.specsList" :key="index" :label="item.specs" :value="item._id"></el-option>
              </el-select>
            </el-form-item>
            <!-- 下单数量 -->
            <el-form-item>
              <el-input v-model="item.weight" placeholder="请输入"></el-input>
            </el-form-item>
            <el-button
              v-if="index !== 0"
              type="text"
              class="btn btn-del"
              @click="deleteRawMaterial(index)"
            >删除</el-button
            >
          </div>
        </div>
        <el-button type="text" class="btn btn-add" @click="handleAdd"
        >添加</el-button
        >
      </el-form-item>
      <div class="bottom">
        <el-form-item label="工艺要求" prop="technologicalRequirements">
          <el-select v-model="ruleForm.technologicalRequirements" filterable allow-create placeholder="请选择">
            <el-option v-for="(item,index) in TECH_REQUIRES" :key="index" :value="item" :label="item"></el-option>
          </el-select>
          <!-- <el-input
            placeholder="请输入"
            v-model="ruleForm.technologicalRequirements"
          ></el-input> -->
        </el-form-item>
        <el-form-item label="复合胶辊" prop="complexRubberRoller">
          <el-select v-model="ruleForm.complexRubberRoller" allow-create filterable placeholder="请选择">
            <el-option v-for="(item,index) in COMPLEX_RUBBER_ROLLER" :key="index" :value="item" :label="item"></el-option>
          </el-select>
          <!-- <el-input
            placeholder="请输入"
            v-model="ruleForm.complexRubberRoller"
          ></el-input> -->
        </el-form-item>
        <el-form-item label="胶粘剂" prop="adhesive">
          <el-input placeholder="请输入" v-model="ruleForm.adhesive"></el-input>
        </el-form-item>
        <el-form-item label="印刷油墨">
          <el-input placeholder="请输入" v-model="ruleForm.printInk"></el-input>
        </el-form-item>
        <el-form-item label="版号" prop="editionNum">
          <el-input placeholder="请输入" v-model="ruleForm.editionNum"></el-input>
        </el-form-item>
      </div>
      <el-form-item label="印刷" style="width: 978px;">
        <el-select v-model="ruleForm.print" filterable allow-create placeholder="请选择">
          <el-option v-for="(item,index) in PRINT" :key="index" :value="item" :label="item"></el-option>
        </el-select>
        <!-- <el-input placeholder="请输入" v-model="ruleForm.print"></el-input> -->
      </el-form-item>
      <div class="bottom">
        <el-form-item label="复合">
          <el-select v-model="ruleForm.reunite" filterable allow-create placeholder="请选择">
            <el-option v-for="(item,index) in REUNITE" :key="index" :value="item" :label="item"></el-option>
          </el-select>
          <!-- <el-input placeholder="请输入" v-model="ruleForm.reunite"></el-input> -->
        </el-form-item>
        <el-form-item label="分切" prop="slitting">
          <el-input placeholder="请输入" v-model="ruleForm.slitting"></el-input>
        </el-form-item>
        <el-form-item label="袋型" prop="bagType">
          <el-select v-model="ruleForm.bagType" filterable allow-create placeholder="请选择">
            <el-option v-for="(item,index) in BAG_TYPE" :key="index" :value="item" :label="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封口位置及尺寸" prop="sealPositionSize">
          <el-select v-model="ruleForm.sealPositionSize" allow-create>
            <el-option v-for="(item,index) in SEAL_POSITION_SIZE" :key="index" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="成型板" prop="formingPlate">
          <el-input
            placeholder="请输入"
            v-model="ruleForm.formingPlate"
          ></el-input>
        </el-form-item>
        <el-form-item label="制袋要求">
          <el-input
            placeholder="请输入"
            v-model="ruleForm.bagMakingRequirements"
          ></el-input>
        </el-form-item>
        <el-form-item label="包装要求">
          <el-select
            v-model="ruleForm.PackagingRequirements"
            multiple
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option label="编织袋" value="编织袋"></el-option>
            <el-option label="纸箱" value="纸箱"></el-option>
            <el-option label="套内袋" value="套内袋"></el-option>
            <el-option label="合格证" value="合格证"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from 'lodash'
import { ElMessage } from 'element-plus'
import { ref, computed, reactive, toRefs, onMounted } from 'vue'
import {
  BAG_TYPE,
  SEAL_POSITION_SIZE,
  CRAFT,
  RAW_MATERIAL,
  PRODUCT_MIX,
  TECH_REQUIRES,
  PRINT,
  REUNITE,
  COMPLEX_RUBBER_ROLLER
} from '@/utils/baseData'
// import { useRoute } from 'vue-router'
// import * as math from 'mathjs'
import {
  saveTemplateApi,
  getTemplateItemApi,
  getTypeSelectApi,
  getSpecByTypeApi,
  getProductTypeSelectApi,
  getProductSpecByTypeApi
} from './fetch'

export default {
  props: {
    id: [Number, String],
    refreshList: Function
  },
  setup (props) {
    const form = ref(null)

    const rawMaterialItem = {
      name: '',
      require: '',
      specs: '',
      weight: '',
      specsList: []
    }
    const state = reactive({
      SEAL_POSITION_SIZE,
      BAG_TYPE,
      CRAFT,
      RAW_MATERIAL,
      PRODUCT_MIX,
      TECH_REQUIRES,
      REUNITE,
      PRINT,
      COMPLEX_RUBBER_ROLLER,
      visible: true,
      loading: false,
      allType: [],
      productTypeList: [],
      productSpecList: [],
      ruleForm: {
        // 成品类型ID
        productTypeId: undefined,
        // 成品规格ID
        productSpecId: undefined,
        // 产品名称
        productName: '',
        // 产品结构
        productStructure: '',
        // 厚度
        thickness: '',
        // 成品规格
        productSpec: '',
        // 数量
        quantity: '',
        // 印刷量
        printQuantity: '',
        // 原材料信息
        rawMaterial: [_.cloneDeep(rawMaterialItem)],
        // 工艺要求
        technologicalRequirements: '',
        // 复合胶辊
        complexRubberRoller: '',
        // 胶粘剂
        adhesive: '',
        // 印刷油墨
        printInk: '',
        // 版号
        editionNum: '',
        // 印刷
        print: '',
        // 复合
        reunite: '',
        // 分切
        slitting: '',
        // 袋型
        bagType: '',
        // 封口位置及尺寸
        sealPositionSize: '',
        // 成型板
        formingPlate: '',
        // 包装要求
        PackagingRequirements: [],
        // 制袋要求
        bagMakingRequirements: ''
      },

      rules: {
        // 成品类型ID
        productTypeId: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] }
        ],
        // 成品规格ID
        productSpecId: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] }
        ],
        // 产品名称
        productName: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 工艺要求
        technologicalRequirements: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 复合胶辊
        complexRubberRoller: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 胶粘剂
        adhesive: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 版号
        editionNum: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 分切
        slitting: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 袋型
        bagType: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 封口位置及尺寸
        sealPositionSize: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 成型板
        formingPlate: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 工艺流程
        name: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 原料
        require: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 原料规格
        specs: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        // 重量
        weight: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ]
      }
    })

    onMounted(async () => {
      try {
        const aa = await getTypeSelectApi()
        state.allType = aa
        state.productTypeList = await getProductTypeSelectApi()
        if (props.id) {
          state.loading = true
          const res = await getTemplateItemApi({
            id: props.id
          })
          for (let i = 0; i < res.rawMaterial.length; i++) {
            const item = res.rawMaterial[i]
            console.log(item)
            item.specsList = await getSpecByTypeApi({
              typeId: item.require
            })
          }
          state.productSpecList = await getProductSpecByTypeApi({
            productTypeId: res.productTypeId
          })
          res.PackagingRequirements = res.PackagingRequirements && res.PackagingRequirements.split(',')
          state.ruleForm = res
        }
      } catch (e) {

      } finally {
        state.loading = false
      }
    })

    const title = computed(() => {
      return props.id ? '编辑模板' : '新增模板'
    })

    const onSubmit = () => {
      form.value.validate(async (valid) => {
        if (valid) {
          try {
            const data = _.cloneDeep(state.ruleForm)
            if (data.PackagingRequirements) {
              data.PackagingRequirements =
              state.ruleForm.PackagingRequirements.join(',')
            }
            const typeName = state.productTypeList.find(bar => bar._id === data.productTypeId).productTypeName
            const specName = state.productSpecList.find(bar => bar._id === data.productSpecId).productSpecsName

            data.productName = typeName + '-' + specName
            await saveTemplateApi(data)
            state.visible = false
            props.refreshList()
            ElMessage({
              message: '保存成功！',
              type: 'success'
            })
          } catch (e) {
            console.dir(e)
            ElMessage({
              message: e.msg || e,
              type: 'error'
            })
          }
        } else {
          ElMessage({
            message: '请输入必填项',
            type: 'error'
          })
        }
      })
    }

    const onChange = async (id) => {
      state.ruleForm.productSpecId = undefined
      state.productSpecList = await getProductSpecByTypeApi({ productTypeId: id })
    }

    const handleAdd = () => {
      state.ruleForm.rawMaterial.push(_.cloneDeep(rawMaterialItem))
    }

    const deleteRawMaterial = (index) => {
      state.ruleForm.rawMaterial.splice(index, 1)
    }

    const handleTypeChange = async (val, item, index) => {
      item.specs = undefined
      item.specsList = await getSpecByTypeApi({
        typeId: val
      })
    }

    return {
      ...toRefs(state),
      form,
      title,
      onSubmit,
      handleAdd,
      deleteRawMaterial,
      handleTypeChange,
      onChange
    }
  }
}
</script>

<style lang="stylus" scoped>
.form
  .top,.bottom
    display flex
    flex-wrap: wrap
:deep(.rawMaterial)
  .raw_content
    width 900px
    border: 1px solid #eee;
    padding: 20px;
    .head
      display: flex;
      span
        display: inline-block;
        width: 210px;
    .rawMaterial_item
      display: flex;
      .el-form-item
        margin-right: 20px;
        .el-form-item__content
          width 190px
      .btn-del
        padding: 0;
        color: #F56C6C;
        height: 30px;

</style>
