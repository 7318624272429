import Request from '@/utils/request'

export function getTemplateApi (data) {
  return Request({
    url: '/template/list',
    params: data
  })
}

// 获取原材料类别
export function getTypeSelectApi (data) {
  return Request({
    url: '/type/all'
  })
}

// 保存模板
export function saveTemplateApi (data) {
  return Request({
    url: '/template/add',
    method: 'post',
    data
  })
}

export function getProductTypeSelectApi () {
  return Request({
    url: '/product/type/all'
  })
}

export function getProductSpecByTypeApi (data) {
  return Request({
    url: '/product/specsbytype',
    params: data
  })
}

// 模板
export function getTemplateItemApi (data) {
  return Request({
    url: '/template/item',
    params: data
  })
}

// 删除模版
export function delTemplateApi (data) {
  return Request({
    url: '/template/del',
    method: 'post',
    data
  })
}

export function getSpecByTypeApi (data) {
  return Request({
    url: '/specsbytype',
    params: data
  })
}
