<template>
  <div v-loading="loading" class="template">
    <div class="top">
      <el-form :model="filterForm" inline @submit.prevent="getList">
        <el-form-item label="产品名称">
          <el-input v-model="filterForm.productName" clearable placeholder="全部"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="submit" @click="getList">查询</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button @click="handleAdd" type="primary">新增模板</el-button>
      </div>
    </div>
    <el-table :data="tableData" stripe>
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column label="操作" >
        <template #default="scope">
          <el-button type="text" @click="handleEdit(scope.row._id)">编辑</el-button>
          <el-button type="text" @click="delTemplate(scope.row._id)" style="color:#F56C6C;">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:current-page="currentPage"
      :page-size="20"
      layout="total, prev, pager, next"
      :total="total"
      @current-change="handleCurrentChange"
    />
    <EditDialog v-if="editDialogShow" :id="currentId" :refresh-list="getList" @close="handleDialogClose"/>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, inject } from 'vue'
import EditDialog from './edit.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { getTemplateApi, delTemplateApi } from './fetch'
export default {
  name: 'TemplatePage',
  components: {
    EditDialog
  },
  setup () {
    const $msg = inject('$msg')
    const state = reactive({
      tableData: [],
      currentPage: 1,
      filterForm: {
        productName: ''
      },
      total: 0,
      loading: false,
      editDialogShow: false,
      currentId: undefined
    })

    onMounted(async () => {
      await getList()
    })

    const getList = async () => {
      try {
        state.loading = true
        const res = await getTemplateApi({
          date: '',
          page: state.currentPage,
          rows: 20,
          ...state.filterForm
        })
        state.tableData = res.data
        state.total = res.total
      } catch (e) {
        $msg({
          message: e,
          type: 'error'
        })
      } finally {
        state.loading = false
      }
    }

    const handleEdit = (id) => {
      state.currentId = id
      state.editDialogShow = true
    }

    const handleAdd = () => {
      state.editDialogShow = true
    }

    const handleDialogClose = () => {
      state.editDialogShow = false
      state.currentId = undefined
    }
    const delTemplate = id => {
      ElMessageBox.confirm(
        '确定删除该模版吗？',
        '删除',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(async () => {
          await delTemplateApi({
            id
          })
          $msg({
            message: '删除成功！',
            type: 'success'
          })
          await getList()
        })
        .catch((e) => {
          if (e !== 'cancel') {
            ElMessage({
              type: 'error',
              message: e.msg || e
            })
          }
        })
    }
    const handleCurrentChange = async () => {
      try {
        await getList()
      } catch (e) {
        this.$error(e)
      }
    }

    return {
      ...toRefs(state),
      getList,
      handleAdd,
      handleDialogClose,
      handleEdit,
      delTemplate,
      handleCurrentChange
    }
  }
}
</script>

<style lang="stylus" scoped>
.template
  .top
    display flex
    justify-content space-between
</style>
